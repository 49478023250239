import { Injectable, inject } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { debounceTime, distinctUntilChanged, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class RentalApplicationFilterFormService {
  private formBuilder = inject(FormBuilder);

  form: FormGroup = this.formBuilder.group({
    association: [],
    contact: [],
    depositFrom: [],
    depositTo: [],
    moveDateFrom: [],
    moveDateTo: [],
    name: [],
  });

  formChange$ = this.form.valueChanges.pipe(
    debounceTime(500),
    distinctUntilChanged(),
    map(values => {
      const filters: any = {}; // object refer to table key

      if (values.association) filters.association = values.association;
      if (values.contact) filters.contact = values.contact;
      if (values.depositFrom) filters.depositFrom = Number(values.depositFrom);
      if (values.depositTo) filters.depositTo = Number(values.depositTo);
      if (values.moveDateFrom)
        filters.moveDateFrom = new Date(values.moveDateFrom);
      if (values.moveDateTo) filters.moveDateTo = new Date(values.moveDateTo);
      if (values.name) filters.name = values.name;

      return filters;
    })
  );

  association$ = this.form.get("association")?.valueChanges;
  contact$ = this.form.get("contact")?.valueChanges;
  depositFrom$ = this.form.get("depositFrom")?.valueChanges;
  depositTo$ = this.form.get("depositTo")?.valueChanges;
  moveDateFrom$ = this.form.get("moveDateFrom")?.valueChanges;
  moveDateTo$ = this.form.get("moveDateTo")?.valueChanges;
  name$ = this.form.get("name")?.valueChanges;

  reset() {
    this.form.patchValue({
      association: null,
      contact: null,
      depositFrom: null,
      depositTo: null,
      moveDateFrom: null,
      moveDateTo: null,
      name: null,
    });
  }
}
