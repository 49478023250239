import { Injectable, inject } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { debounceTime, distinctUntilChanged, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class MeetingFilterFormService {
  private formBuilder = inject(FormBuilder);

  form: FormGroup = this.formBuilder.group({
    address: [],
    dateFrom: [],
    dateTo: [],
    description: [],
    staffAttended: [],
    subject: [],
    statuses: [],
  });

  formChange$ = this.form.valueChanges.pipe(
    debounceTime(500),
    distinctUntilChanged(),
    map(values => {
      const filters: any = {}; // object refer to table key

      if (values.address) filters.address = values.address;
      if (values.dateFrom) filters.dateFrom = new Date(values.dateFrom);
      if (values.dateTo) filters.dateTo = new Date(values.dateTo);
      if (values.description) filters.description = values.description;
      if (values.staffAttended) filters.staffAttended = values.staffAttended;
      if (values.subject) filters.subject = values.subject;
      if (values.statuses) filters.subject = values.statuses;

      return filters;
    })
  );

  address$ = this.form.get("address")?.valueChanges;
  dateFrom$ = this.form.get("dateFrom")?.valueChanges;
  dateTo$ = this.form.get("dateTo")?.valueChanges;
  description$ = this.form.get("description")?.valueChanges;
  staffAttended$ = this.form.get("staffAttended")?.valueChanges;
  subject$ = this.form.get("subject")?.valueChanges;
  statuses$ = this.form.get("statuses")?.valueChanges;

  reset() {
    this.form.patchValue({
      address: null,
      dateFrom: null,
      dateTo: null,
      description: null,
      staffAttended: null,
      subject: null,
      statuses: []
    });
  }
}
