import { Injectable, inject } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { debounceTime, distinctUntilChanged, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class DraftLeaseFilterFormService {
  private formBuilder = inject(FormBuilder);

  form: FormGroup = this.formBuilder.group({
    association: [],
    generationDateFrom: [],
    generationDateTo: [],
    name: [],
    nextStep: [],
    term: [],
  });

  formChange$ = this.form.valueChanges.pipe(
    debounceTime(500),
    distinctUntilChanged(),
    map(values => {
      const filters: any = {}; // object refer to table key

      if (values.amountFrom) filters.amountFrom = Number(values.amountFrom);

      if (values.association) filters.association = values.association;
      if (values.generationDateFrom)
        filters.generationDateFrom = new Date(values.generationDateFrom);
      if (values.generationDateTo)
        filters.generationDateTo = new Date(values.generationDateTo);
      if (values.name) filters.name = values.name;
      if (values.nextStep) filters.nextStep = values.nextStep;
      if (values.term) filters.term = values.term;

      return filters;
    })
  );

  association$ = this.form.get("association")?.valueChanges;
  generationDateFrom$ = this.form.get("generationDateFrom")?.valueChanges;
  generationDateTo$ = this.form.get("generationDateTo")?.valueChanges;
  name$ = this.form.get("name")?.valueChanges;
  nextStep$ = this.form.get("nextStep")?.valueChanges;
  term$ = this.form.get("term")?.valueChanges;

  reset() {
    this.form.patchValue({
      association: null,
      generationDateFrom: null,
      generationDateTo: null,
      name: null,
      nextStep: null,
      term: null,
    });
  }
}
