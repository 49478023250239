import { Injectable, inject } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { debounceTime, distinctUntilChanged, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class ArchitecturalReviewFilterFormService {
  private formBuilder = inject(FormBuilder);

  form: FormGroup = this.formBuilder.group({
    address: [],
    age: [],
    dateFrom: [],
    dateTo: [],
    projectName: [],
    category: [],
    statuses: [],
  });

  formChange$ = this.form.valueChanges.pipe(
    debounceTime(500),
    distinctUntilChanged(),
    map(values => {
      const filters: any = {}; // object refer to table key

      if (values.address) filters.address = values.address;
      if (values.age) filters.age = values.age;
      if (values.dateFrom)
        filters.dateFrom = new Date(values.dateFrom).toJSON().slice(0, 10); // format date as 'YYYY-MM-DD'
      if (values.dateTo)
        filters.dateTo = new Date(values.dateTo).toJSON().slice(0, 10); // format date as 'YYYY-MM-DD'
      if (values.projectName) filters.projectName = values.projectName;
      if (values.statuses) filters.statuses = values.statuses;
      if (values.cateogry) filters.category = values.category;

      return filters;
    })
  );

  address$ = this.form.get("address")?.valueChanges;
  age$ = this.form.get("age")?.valueChanges;
  dateFrom$ = this.form.get("dateFrom")?.valueChanges;
  dateTo$ = this.form.get("dateTo")?.valueChanges;
  projectName$ = this.form.get("projectName")?.valueChanges;
  statuses$ = this.form.get("statuses")?.valueChanges;
  category$ = this.form.get("category")?.valueChanges;

  reset() {
    this.form.patchValue({
      address: [],
      age: [],
      dateFrom: [],
      dateTo: [],
      projectName: [],
      category: [],
      statuses: [],
    });
  }
}
