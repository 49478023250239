import { Injectable, inject } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { debounceTime, distinctUntilChanged, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class JournalEntryFilterFormService {
  private formBuilder = inject(FormBuilder);

  form: FormGroup = this.formBuilder.group({
    creditFrom: [],
    creditTo: [],
    dateFrom: [],
    dateTo: [],
    debitFrom: [],
    debitTo: [],
    memo: [],
    refNo: [],
    transactionNumberFrom: [],
    transactionNumberTo: [],
  });

  formChange$ = this.form.valueChanges.pipe(
    debounceTime(500),
    distinctUntilChanged(),
    map(values => {
      const filters: any = {}; // object refer to table key

      if (values.creditFrom)
        filters.creditTotalFrom = Number(values.creditFrom);
      if (values.creditTo) filters.creditTotalTo = Number(values.creditTo);
      if (values.dateFrom)
        filters.dateFrom = new Date(values.dateFrom).toJSON().slice(0, 10); // format date as 'YYYY-MM-DD'
      if (values.dateTo)
        filters.dateTo = new Date(values.dateTo).toJSON().slice(0, 10); // format date as 'YYYY-MM-DD'
      if (values.debitFrom) filters.debitTotalFrom = Number(values.debitFrom);
      if (values.debitTo) filters.debitTotalTo = Number(values.debitTo);
      if (values.memo) filters.memo = values.memo;
      if (values.refNo) filters.referenceNumber = values.refNo;
      if (values.transactionNumberFrom)
        filters.transactionNumberFrom = Number(values.transactionNumberFrom);
      if (values.transactionNumberTo)
        filters.transactionNumberTo = Number(values.transactionNumberTo);

      return filters;
    })
  );

  creditFrom$ = this.form.get("creditFrom")?.valueChanges;
  creditTo$ = this.form.get("creditTo")?.valueChanges;
  dateFrom$ = this.form.get("dateFrom")?.valueChanges;
  dateTo$ = this.form.get("dateTo")?.valueChanges;
  debitFrom$ = this.form.get("debitFrom")?.valueChanges;
  debitTo$ = this.form.get("debitTo")?.valueChanges;
  memo$ = this.form.get("memo")?.valueChanges;
  refNo$ = this.form.get("refNo")?.valueChanges;
  transactionNumberFrom$ = this.form.get("transactionNumberFrom")?.valueChanges;
  transactionNumberTo$ = this.form.get("transactionNumberTo")?.valueChanges;

  reset() {
    this.form.patchValue({
      creditFrom: null,
      creditTo: null,
      dateFrom: null,
      dateTo: null,
      debitFrom: null,
      debitTo: null,
      memo: null,
      refNo: null,
      transactionNumberFrom: null,
      transactionNumberTo: null,
    });
  }
}
