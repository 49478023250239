import { Injectable, inject } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { debounceTime, distinctUntilChanged, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class BaseKnowledgeFilterFormService {
  private formBuilder = inject(FormBuilder);

  form: FormGroup = this.formBuilder.group({
    chunk: [],
    fileCountFrom: [],
    fileCountTo: [],
    name: [],
    token: [],
    updatedDateFrom: [],
    updatedDateTo: [],
  });

  formChange$ = this.form.valueChanges.pipe(
    debounceTime(500),
    distinctUntilChanged(),
    map(values => {
      const filters: any = {}; // object refer to table key

      if (values.chunk) filters.chunk = values.chunk;
      if (values.fileCountFrom)
        filters.fileCountFrom = Number(values.fileCountFrom);
      if (values.fileCountTo) filters.fileCountTo = Number(values.fileCountTo);
      if (values.name) filters.name = values.name;
      if (values.token) filters.token = values.token;
      if (values.updatedDateFrom)
        filters.updatedDateFrom = new Date(values.updatedDateFrom);
      if (values.updatedDateTo)
        filters.updatedDateTo = new Date(values.updatedDateTo);

      return filters;
    })
  );

  chunk$ = this.form.get("chunk")?.valueChanges;
  fileCountFrom$ = this.form.get("fileCountFrom")?.valueChanges;
  fileCountTo$ = this.form.get("fileCountTo")?.valueChanges;
  name$ = this.form.get("name")?.valueChanges;
  token$ = this.form.get("token")?.valueChanges;
  updatedDateFrom$ = this.form.get("updatedDateFrom")?.valueChanges;
  updatedDateTo$ = this.form.get("updatedDateTo")?.valueChanges;

  reset() {
    this.form.patchValue({
      chunk: null,
      fileCountFrom: null,
      fileCountTo: null,
      name: null,
      token: null,
      updatedDateFrom: null,
      updatedDateTo: null,
    });
  }
}
