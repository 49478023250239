import { Injectable, inject } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { debounceTime, distinctUntilChanged, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class WorkOrderFilterFormService {
  private formBuilder = inject(FormBuilder);

  form: FormGroup = this.formBuilder.group({
    category: [],
    customer: [],
    priority: [],
    status: [],
    title: [],
    vendor: [],
  });

  formChange$ = this.form.valueChanges.pipe(
    debounceTime(500),
    distinctUntilChanged(),
    map(values => {
      const filters: any = {}; // object refer to table key

      if (values.category) filters.category = values.category;
      if (values.customer) filters.customer = values.customer;
      if (values.priority) filters.priority = values.priority;
      if (values.status) filters.status = values.status;
      if (values.title) filters.title = values.title;
      if (values.vendor) filters.vendor = values.vendor;

      return filters;
    })
  );

  category$ = this.form.get("category")?.valueChanges;
  customer$ = this.form.get("customer")?.valueChanges;
  priority$ = this.form.get("priority")?.valueChanges;
  status$ = this.form.get("status")?.valueChanges;
  title$ = this.form.get("title")?.valueChanges;
  vendor$ = this.form.get("vendor")?.valueChanges;

  reset() {
    this.form.patchValue({
      category: null,
      customer: null,
      priority: null,
      status: null,
      title: null,
      vendor: null,
    });
  }
}
