import { Injectable, inject } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { debounceTime, distinctUntilChanged, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class ActiveLeaseFilterFormService {
  private formBuilder = inject(FormBuilder);

  form: FormGroup = this.formBuilder.group({
    association: [],
    balanceFrom: [],
    balanceTo: [],
    depositFrom: [],
    depositTo: [],
    name: [],
    rentFrom: [],
    rentTo: [],
    term: [],
  });

  formChange$ = this.form.valueChanges.pipe(
    debounceTime(500),
    distinctUntilChanged(),
    map(values => {
      const filters: any = {}; // object refer to table key

      if (values.association) filters.association = values.association;
      if (values.balanceFrom) filters.balanceFrom = Number(values.balanceFrom);
      if (values.balanceTo) filters.balanceTo = Number(values.balanceTo);
      if (values.depositFrom) filters.depositFrom = Number(values.depositFrom);
      if (values.depositTo) filters.depositTo = Number(values.depositTo);
      if (values.name) filters.name = values.name;
      if (values.rentFrom) filters.rentFrom = Number(values.rentFrom);
      if (values.rentTo) filters.rentTo = Number(values.rentTo);
      if (values.term) filters.term = values.term;

      return filters;
    })
  );

  association$ = this.form.get("association")?.valueChanges;
  balanceFrom$ = this.form.get("balanceFrom")?.valueChanges;
  balanceTo$ = this.form.get("balanceTo")?.valueChanges;
  depositFrom$ = this.form.get("depositFrom")?.valueChanges;
  depositTo$ = this.form.get("depositTo")?.valueChanges;
  name$ = this.form.get("name")?.valueChanges;
  rentFrom$ = this.form.get("rentFrom")?.valueChanges;
  rentTo$ = this.form.get("rentTo")?.valueChanges;
  term$ = this.form.get("term")?.valueChanges;

  reset() {
    this.form.patchValue({
      association: null,
      balanceFrom: null,
      balanceTo: null,
      depositFrom: null,
      depositTo: null,
      name: null,
      rentFrom: null,
      rentTo: null,
      term: null,
    });
  }
}
