import { Injectable, inject } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { debounceTime, distinctUntilChanged, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class TaskFilterFormService {
  private formBuilder = inject(FormBuilder);

  form: FormGroup = this.formBuilder.group({
    assignee: [],
    dueDateFrom: [],
    dueDateTo: [],
    for: [],
    name: [],
    status: [],
    type: [],
  });

  formChange$ = this.form.valueChanges.pipe(
    debounceTime(500),
    distinctUntilChanged(),
    map(values => {
      const filters: any = {}; // object refer to table key

      if (values.assignee) filters.assignee = values.assignee;
      if (values.dueDateFrom)
        filters.dueDateFrom = new Date(values.dueDateFrom);
      if (values.dueDateTo) filters.dueDateTo = new Date(values.dueDateTo);
      if (values.for) filters.for = values.for;
      if (values.name) filters.name = values.name;
      if (values.status) filters.status = values.status;
      if (values.type) filters.type = values.type;

      return filters;
    })
  );

  assignee$ = this.form.get("assignee")?.valueChanges;
  dueDateFrom$ = this.form.get("dueDateFrom")?.valueChanges;
  dueDateTo$ = this.form.get("dueDateTo")?.valueChanges;
  for$ = this.form.get("for")?.valueChanges;
  name$ = this.form.get("name")?.valueChanges;
  status$ = this.form.get("status")?.valueChanges;
  type$ = this.form.get("type")?.valueChanges;

  reset() {
    this.form.patchValue({
      assignee: null,
      dueDateFrom: null,
      dueDateTo: null,
      for: null,
      name: null,
      status: null,
      type: null,
    });
  }
}
