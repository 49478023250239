import { Injectable, inject } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { debounceTime, distinctUntilChanged, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class InvoiceFilterFormService {
  private formBuilder = inject(FormBuilder);

  form: FormGroup = this.formBuilder.group({
    agingDaysFrom: [],
    agingDaysTo: [],
    amountFrom: [],
    amountTo: [],
    customer: [],
    dateFrom: [],
    dateTo: [],
    dueDateFrom: [],
    dueDateTo: [],
    memo: [],
    openBalanceFrom: [],
    openBalanceTo: [],
    refNo: [],
    statuses: [],
  });

  formChange$ = this.form.valueChanges.pipe(
    debounceTime(500),
    distinctUntilChanged(),
    map(values => {
      const filters: any = {}; // object refer to table key

      if (values.agingDaysFrom)
        filters.agingDaysFrom = Number(values.agingDaysFrom);
      if (values.agingDaysTo) filters.agingDaysTo = Number(values.agingDaysTo);
      if (values.amountFrom) filters.subtotalFrom = Number(values.amountFrom);
      if (values.amountTo) filters.subtotalTo = Number(values.amountTo);
      if (values.customer) filters.customerNumber = values.customer;
      if (values.dateFrom)
        filters.dateFrom = new Date(values.dateFrom).toJSON().slice(0, 10); // format date as 'YYYY-MM-DD'
      if (values.dateTo)
        filters.dateTo = new Date(values.dateTo).toJSON().slice(0, 10); // format date as 'YYYY-MM-DD'
      if (values.dueDateFrom)
        filters.dueDateFrom = new Date(values.dueDateFrom)
          .toJSON()
          .slice(0, 10); // format date as 'YYYY-MM-DD'
      if (values.dueDateTo)
        filters.dueDateTo = new Date(values.dueDateTo).toJSON().slice(0, 10); // format date as 'YYYY-MM-DD'
      if (values.memo) filters.memo = values.memo;
      if (values.openBalanceFrom)
        filters.openBalanceFrom = Number(values.openBalanceFrom);
      if (values.openBalanceTo)
        filters.openBalanceTo = Number(values.openBalanceTo);
      if (values.refNo) filters.referenceNumber = values.refNo;
      if (values.statuses) filters.statuses = values.statuses;

      return filters;
    })
  );

  agingDaysFrom$ = this.form.get("agingDaysFrom")?.valueChanges;
  agingDaysTo$ = this.form.get("agingDaysTo")?.valueChanges;
  amountFrom$ = this.form.get("amountFrom")?.valueChanges;
  amountTo$ = this.form.get("amountTo")?.valueChanges;
  customer$ = this.form.get("customer")?.valueChanges;
  dateFrom$ = this.form.get("dateFrom")?.valueChanges;
  dateTo$ = this.form.get("dateTo")?.valueChanges;
  dueDateFrom$ = this.form.get("dueDateFrom")?.valueChanges;
  dueDateTo$ = this.form.get("dueDateTo")?.valueChanges;
  memo$ = this.form.get("memo")?.valueChanges;
  openBalanceFrom$ = this.form.get("openBalanceFrom")?.valueChanges;
  openBalanceTo$ = this.form.get("openBalanceTo")?.valueChanges;
  refNo$ = this.form.get("refNo")?.valueChanges;
  statuses$ = this.form.get("statuses")?.valueChanges;

  reset() {
    this.form.patchValue({
      agingDaysFrom: null,
      agingDaysTo: null,
      amountFrom: null,
      amountTo: null,
      customer: null,
      dateFrom: null,
      dateTo: null,
      dueDateFrom: null,
      dueDateTo: null,
      memo: null,
      openBalanceFrom: null,
      openBalanceTo: null,
      refNo: null,
      statuses: [],
    });
  }
}
